<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit Non-Routine Event</p>
    <validation-observer ref="obs" v-slot="{}">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="nonRoutineEvent.name"
                label="Non-Routine Event Name"
                hint="Name for this Non-Routine Event"
                persistent-hint
                rules="required"
                ref="nameText"
              ></validated-text-field
            ></v-col>
            <v-col sm="6">
              <validated-select-field
                :items="nonRoutineEventTypes"
                item-value="id"
                item-text="name"
                rules="required"
                v-model="nonRoutineEvent.nreTypeId"
                label="Non-Routine Event Type"
                hint="What type of Non-Routine Event is this?"
                persistent-hint
              ></validated-select-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <start-end-date-picker
                name="date range"
                rules="required|valid_date_range"
                v-model="dateRange"
                @change="handleSetDateRange"
                date-format="yyyy-MM-dd"
                ref="startEndDatePicker"
                disabled
              ></start-end-date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              rows="2"
              label="Comments"
              clearable
              clear-icon="mdi-close-circle"
              outlined
              auto-grow
              v-model="nonRoutineEvent.comment"
              class="pr-3 pl-3"
            >
            </v-textarea>
          </v-row>

          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidNonRoutineEvent">
                Save
              </v-btn>
              <v-btn class="ml-5" color="primary" title="Download new energy adjustment spreadsheet" @click="handleDownloadNewSheet()">
                Download New Spreadsheet
              </v-btn>
              <v-btn left class="ml-5" color="secondary" title="Download existing energy adjustment spreadsheet"  @click="openDownloadExistingSheetModal()">
                <v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
              <v-btn class="ml-5" color="secondary" title="Upload energy adjustment spreadsheet" @click="openUploadSheetModal()">
                <v-icon>mdi-cloud-upload-outline</v-icon>
              </v-btn>
              <v-btn class="ml-5" color="secondary" title="View Energy Adjustment Chart" @click="openViewChartModal()">
                <v-icon>mdi-chart-bar</v-icon>
              </v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form></validation-observer>

      <v-dialog width="800" v-model="showUploadSheetModal" >
        <v-card>
          <v-card-title class="headline">Upload spreadsheet</v-card-title>              
              <v-file-input
                  small-chips
                  label="Select Energy Adjustment Data"
                  v-model="energyAdjustmentFile"
                  class="pr-4"
              ></v-file-input>
          <v-card-actions>
            <v-btn @click="handleUploadSheet()" color="primary">Upload</v-btn>
            <v-btn @click="closeUploadSheetModal()" class="ml-5">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="800" v-model="showDownloadExistingSheetModal" >
        <v-card>
          <v-card-title class="headline">Download existing spreadsheet</v-card-title>
          <v-card-text>
            <v-select v-model="selectedVersion" :items="availableVersions"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleDownloadExistingSheet()" color="primary">Download</v-btn>
            <v-btn @click="closeDownloadExistingSheetModal()" class="ml-5">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="800" v-model="showViewChartModal" >
        <v-card>
          <v-card-title class="headline">View Energy Adjustment Chart</v-card-title>              
          <v-card-text>
            <v-select v-model="selectedVersion" :items="availableVersions"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleViewChart()" color="primary">View Chart</v-btn>
            <v-btn @click="closeViewChartModal()" class="ml-5">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </v-container>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import StartEndDatePicker from "@/components/Fields/StartEndDatePicker";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import ValidatedSelectField from "@/components/Fields/ValidatedSelectField";
import api from "../_api";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validated-select-field": ValidatedSelectField,
    "validation-observer": ValidationObserver,
    "start-end-date-picker": StartEndDatePicker,
  },

  data() {
    return {
      valid: true,
      nonRoutineEventId: null,
      nonRoutineEvent: {
        name: null,
        nonRoutineEventTypeId: null,
        startDateTime: null,
        endDateTime: null,
        comment: null,
      },
      nonRoutineEventTypes: [],
      selectedNonRoutineEventType: null,
      dateRange: {},
      isValidNonRoutineEvent: true,

      showUploadSheetModal: false,
      energyAdjustmentFile: null,
      
      showDownloadExistingSheetModal: false,
      showViewChartModal: false,
      availableVersions: [],
      selectedVersion: null,
    };
  },

  async created() {
    var self = this;
    self.nonRoutineEventId = this.$route.params.nonRoutineEventId;
    await api.getNonRoutineEventById(self.nonRoutineEventId)
      .then((response) => {
        self.nonRoutineEvent = response;
        self.getNonRoutineEventTypes();
      })
        .catch((error) => {
          console.log(error);
      })
        .finally(() => {
          this.loading = false
      });  

    this.availableVersions = this.nonRoutineEvent.energyAdjustmentVersions;
    this.selectedVersion = Math.max(this.availableVersions);
    this.dateRange = {
      startDateTime: this.nonRoutineEvent.startDateTime.substring(0, 10),
      endDateTime: this.nonRoutineEvent.endDateTime.substring(0, 10),
    };
  },

  methods: {
    async submit() {
      let self = this
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }

      self.nonRoutineEvent.analyticModels = self.selectedAnalyticModels;
      await api.updateNonRoutineEvent(self.nonRoutineEvent);

      self.$toast.show("Non-routine Event updated", null, "success");
      self.$router.push({ name: "NREIndex" });
    },

    handleSetDateRange(dateRange) {
      this.nonRoutineEvent.startDateTime = dateRange.startDateTime;
      this.nonRoutineEvent.endDateTime = dateRange.endDateTime;

      this.dateRange = {
        startDateTime: dateRange.startDateTime,
        endDateTime: dateRange.endDateTime,
      };
    },

    async getNonRoutineEventTypes() {
      let self = this;
      api.getNonRoutineEventTypes()
        .then((response) => {
          self.nonRoutineEventTypes = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //Download New Sheet
    async handleDownloadNewSheet() {
      try {
        this.loading = true;
        let zipStream = await api.downloadNewEnergyAdjustmentSpreadsheet(this.nonRoutineEventId);
        let fileURL = window.URL.createObjectURL(new Blob([zipStream], {type: 'application/vnd.ms-excel'}));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        var fileName = `${this.nonRoutineEvent.year} Energy Adjustment for ${this.nonRoutineEvent.name}`;
        fileLink.setAttribute("download", fileName + ".xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    
    //Download Existing Sheet
    openDownloadExistingSheetModal() {
      this.showDownloadExistingSheetModal = true;
    },

    closeDownloadExistingSheetModal() {
      this.showDownloadExistingSheetModal = false;
    },

    async handleDownloadExistingSheet() {
      try {
        let zipStream = await api.downloadExistingEnergyAdjustmentSpreadsheet(this.nonRoutineEventId, this.selectedVersion);
        let fileURL = window.URL.createObjectURL(new Blob([zipStream], {type: 'application/vnd.ms-excel'}));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        var fileName = `${this.nonRoutineEvent.year} Energy Adjustment for ${this.nonRoutineEvent.name} v${this.selectedVersion}`;
        fileLink.setAttribute("download", fileName + ".xlsx");
        document.body.appendChild(fileLink);
        
        fileLink.click();
       } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    //Upload Sheet
    openUploadSheetModal() {
      this.showUploadSheetModal = true;
    },

    closeUploadSheetModal() {
      this.showUploadSheetModal = false;
    },

    async handleUploadSheet() {
      try {
        this.loading = true;
        var result = await api.uploadEnergyAdjustmentSpreadsheet(this.nonRoutineEventId, this.energyAdjustmentFile);
        console.log(result);
        this.$router.go(0);
       } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    
    //View Energy Adjustment Chart
    openViewChartModal() {
      this.showViewChartModal = true;
    },

    closeViewChartModal() {
      this.showViewChartModal = false;
    },

    handleViewChart() {
      this.$router.push({name: "NREEnergyAdjustmentChart", 
        params: { nonRoutineEventId: this.nonRoutineEventId, version: this.selectedVersion}});
    },

    handleCancelCrud() {
      this.$router.push({ name: "NREIndex" });
    },
  },
};
</script>